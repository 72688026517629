import { mapState } from "vuex";

export default {
  name: "Auth",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-2.jpg";
    }
  }
};
